<template>
  <component
    :is="voucherComponent"
    :voucher="voucher"
    :can-delete-voucher="variant === LineItemVariant.cart"
  />
</template>
<script setup lang="ts">
import { LineItemVariant } from '../types';
import { lineItemVariantMap } from './variants/maps';
import { LineItemType } from '~/@types/lineItemType';
import type { Voucher } from '~/server/transformers/shop/cartTransformer';

const props = defineProps({
  voucher: {
    type: Object as PropType<Voucher>,
    required: true,
  },
  variant: {
    type: String as PropType<LineItemVariant>,
    required: true,
  },
});

const voucherComponent =
  lineItemVariantMap[props.variant]?.[LineItemType.absoluteVoucher];
</script>
