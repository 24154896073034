<template>
  <component
    :is="variantComponent"
    v-if="variantComponent !== 'inFrame'"
    :line-item="lineItem"
    :bg-class="
      showHints.productIdsWithChange?.has(lineItem.itemId)
        ? 'bg-status-success-lightest'
        : 'bg-white'
    "
    :hide-strike-price="hideStrikePriceOnLineItem"
    @click="$emit('click')"
  />
</template>
<script setup lang="ts">
import type { LineItem } from '~/server/transformers/shop/lineItemTransformer';
import type { LineItemVariant } from '../types';
import { useCartStore } from '~/stores/useCart';
import { storeToRefs } from 'pinia';
import { lineItemVariantMap } from './variants/maps';

const props = defineProps({
  lineItem: {
    type: Object as PropType<LineItem>,
    required: true,
  },
  variant: {
    type: String as PropType<LineItemVariant>,
    required: true,
  },
  hideStrikePriceOnLineItem: {
    type: Boolean,
    default: false,
    requried: false,
  },
});

defineEmits<{
  (e: 'click'): void;
}>();

const variantComponent =
  lineItemVariantMap[props.variant]?.[props.lineItem.type];
const { hints: showHints } = storeToRefs(useCartStore());
</script>
