<template>
  <div class="flex flex-col gap-lg px-xs md:px-0">
    <template v-for="item in lineItems" :key="item.itemId">
      <LineItem
        :line-item="item"
        :variant="variant"
        :hide-strike-price-on-line-item="hideStrikePriceOnLineItem"
        @click="$emit('click')"
        @change-count="
          (productId: string, productQuantity: number) => {
            $emit('change-count', productId, productQuantity);
          }
        "
      />
    </template>
    <AbsoluteVoucher
      v-if="showVoucher()"
      :voucher="voucher"
      :variant="variant"
    />
  </div>
</template>
<script setup lang="ts">
import type { LineItem as LineItemType } from '~/server/transformers/shop/lineItemTransformer';
import type { LineItemVariant } from './types';
import LineItem from './components/lineItem.vue';
import AbsoluteVoucher from './components/absoluteVoucher.vue';
import type { Voucher } from '~/server/transformers/shop/cartTransformer';

const props = defineProps({
  lineItems: {
    type: Object as PropType<LineItemType[]>,
    required: true,
  },
  variant: {
    type: String as PropType<LineItemVariant>,
    required: true,
  },
  voucher: {
    type: Object as PropType<Voucher>,
    required: false,
    default: null,
  },
  hideStrikePriceOnLineItem: {
    type: Boolean,
    default: false,
    requried: false,
  },
});
defineEmits<{
  (e: 'click'): void;
  (e: 'change-count', productId: string, productCount: number): void;
}>();
function showVoucher() {
  if (!props.voucher) return false;
  return !props.lineItems.find((item) => item.promotions?.[0]);
}
</script>
