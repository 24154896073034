import { LineItemType } from '~/@types/lineItemType';
import { LineItemVariant } from '../../types';

export const lineItemVariantMap: Record<
  LineItemVariant,
  Partial<
    Record<LineItemType, 'inFrame' | ReturnType<typeof defineAsyncComponent>>
  >
> = {
  [LineItemVariant.cart]: {
    [LineItemType.product]: defineAsyncComponent(
      () => import('./product/cart.vue'),
    ),
    [LineItemType.subscription]: defineAsyncComponent(
      () => import('./subscription/cart.vue'),
    ),
    [LineItemType.campaign]: defineAsyncComponent(
      () => import('./campaign/cart.vue'),
    ),
    [LineItemType.absoluteVoucher]: defineAsyncComponent(
      () => import('./absoluteVoucher/cart.vue'),
    ),
  },
  [LineItemVariant.checkout]: {
    [LineItemType.product]: defineAsyncComponent(
      () => import('./product/checkout.vue'),
    ),
    [LineItemType.incompleteProduct]: defineAsyncComponent(
      () => import('./product/incompleteCheckout.vue'),
    ),
    [LineItemType.subscription]: defineAsyncComponent(
      () => import('./subscription/checkout.vue'),
    ),
    [LineItemType.campaign]: defineAsyncComponent(
      () => import('./campaign/checkout.vue'),
    ),
    [LineItemType.absoluteVoucher]: defineAsyncComponent(
      () => import('./absoluteVoucher/checkout.vue'),
    ),
  },
  [LineItemVariant.review]: {
    [LineItemType.product]: defineAsyncComponent(
      () => import('./product/review.vue'),
    ),
    [LineItemType.subscription]: defineAsyncComponent(
      () => import('./subscription/review.vue'),
    ),
    [LineItemType.campaign]: defineAsyncComponent(
      () => import('./campaign/review.vue'),
    ),
    [LineItemType.absoluteVoucher]: defineAsyncComponent(
      () => import('./absoluteVoucher/review.vue'),
    ),
  },
  [LineItemVariant.miniBasket]: {
    [LineItemType.product]: defineAsyncComponent(
      () => import('./product/miniBasket.vue'),
    ),
    [LineItemType.subscription]: defineAsyncComponent(
      () => import('./subscription/miniBasket.vue'),
    ),
    [LineItemType.campaign]: defineAsyncComponent(
      () => import('./campaign/miniBasket.vue'),
    ),
    [LineItemType.absoluteVoucher]: defineAsyncComponent(
      () => import('./absoluteVoucher/miniBasket.vue'),
    ),
  },
  [LineItemVariant.order]: {
    [LineItemType.product]: defineAsyncComponent(
      () => import('./product/order.vue'),
    ),
    [LineItemType.incompleteProduct]: defineAsyncComponent(
      () => import('./product/order.vue'),
    ),
    [LineItemType.subscription]: defineAsyncComponent(
      () => import('./subscription/order.vue'),
    ),
    [LineItemType.incompleteSubscription]: defineAsyncComponent(
      () => import('./subscription/order.vue'),
    ),
    [LineItemType.campaign]: defineAsyncComponent(
      () => import('./campaign/order.vue'),
    ),
    [LineItemType.incompleteCampaign]: defineAsyncComponent(
      () => import('./campaign/order.vue'),
    ),
    [LineItemType.absoluteVoucher]: defineAsyncComponent(
      () => import('./absoluteVoucher/order.vue'),
    ),
    [LineItemType.account]: defineAsyncComponent(
      () => import('./account/invoice.vue'),
    ),
  },
  [LineItemVariant.return]: {
    [LineItemType.product]: defineAsyncComponent(
      () => import('./product/returnItem.vue'),
    ),
    [LineItemType.incompleteProduct]: defineAsyncComponent(
      () => import('./product/returnItem.vue'),
    ),
  },
  [LineItemVariant.returnReview]: {
    [LineItemType.product]: defineAsyncComponent(
      () => import('./product/returnReview.vue'),
    ),
    [LineItemType.incompleteProduct]: defineAsyncComponent(
      () => import('./product/returnReview.vue'),
    ),
  },
  [LineItemVariant.invoice]: {
    [LineItemType.product]: defineAsyncComponent(
      () => import('./product/invoice.vue'),
    ),
    [LineItemType.incompleteProduct]: defineAsyncComponent(
      () => import('./product/invoice.vue'),
    ),
    [LineItemType.campaign]: defineAsyncComponent(
      () => import('./campaign/order.vue'),
    ),
    [LineItemType.incompleteCampaign]: defineAsyncComponent(
      () => import('./campaign/order.vue'),
    ),
    [LineItemType.absoluteVoucher]: defineAsyncComponent(
      () => import('./absoluteVoucher/invoice.vue'),
    ),
    [LineItemType.account]: defineAsyncComponent(
      () => import('./account/invoice.vue'),
    ),
  },
};
