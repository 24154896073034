export enum LineItemVariant {
  cart = 'cart',
  checkout = 'checkout',
  review = 'review',
  miniBasket = 'miniBasket',
  order = 'order',
  return = 'return',
  returnReview = 'returnReview',
  invoice = 'invoice',
}

export type BgColors =
  | 'bg-white'
  | 'bg-status-success-lightest'
  | 'bg-transparent'
  | 'bg-primary-base-opc10';
